/* Let's default this puppy out
-------------------------------------------------------------------------------*/

html, body, body div, span, object, iframe, h1, 
h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address,
 cite, code, del, dfn, em, img, ins, kbd, q, samp, small,
  strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, 
  fieldset, form, label, legend, table, caption, tbody, 
  tfoot, thead, tr, th, td, article, aside, figure, footer, 
  header, menu, nav, section, time, mark, audio, video, details, summary {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-weight: normal;
	vertical-align: baseline;
	background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {display: block;}

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */

*, body,
*:before,
*:after {
	box-sizing: border-box;
}


/* Responsive images and other embedded objects
*/
img, object, embed {max-width: 100%;}
img {display: block;}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/

/* force a vertical scrollbar to prevent a jumpy page */
html {overflow-y: auto;overflow-x: hidden}

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before,
blockquote:after,
q:before,
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

/* tables still need cellspacing="0" in the markup */
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle;}

pre {
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
}


select, input, textarea {font: 99% sans-serif;}

table {font-size: inherit; font: 100%;}

small {font-size: 85%;}

strong {font-weight: bold;}

td, td img {vertical-align: top;}

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

/* standardize any monospaced elements */
pre, code, kbd, samp {font-family: monospace, sans-serif;}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {cursor: pointer;}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0; padding: 0;}

/* scale images in IE7 more attractively */
.ie7 img {-ms-interpolation-mode: bicubic;}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/

/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* a tag text-decoration hiding */
a{
	text-decoration: none;
}

/* li tag style reseting */
li{
	list-style-type: none;
}

/* all input appearance reseting*/
input, button{
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	outline: 0;
	border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
input[type="radio"] {
	vertical-align: text-bottom;
    -webkit-appearance: radio;
}
input[type="checkbox"] {
	vertical-align: bottom;
    -webkit-appearance: checkbox;
}

.ie7 input[type="checkbox"] {vertical-align: baseline;}
.ie6 input {vertical-align: text-bottom;}

/* number type inupt spinners hiding */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
-webkit-appearance: none;
    margin: 0;
}

audio {
  position: absolute;
  z-index: -100;
}

body {
  margin: 0;
  font-family: 'Anton', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff
}

*, *::after, *::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bull-container{
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  padding-block-start: 20px;
  background-image: url('./banner.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bull-container h1 {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.3rem;
}
.greed-brand{
  font-size: 50px;
  font-family: 'Anton';
  color: #fff;
  text-transform: uppercase;
}

.bull-container > img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.bull-form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  align-items: center;
  z-index: 20;
  max-width: 370px;
  min-width: fit-content;
  width: 100%;
  min-height: 320px;
  padding: 8px;
  text-align: center;
  background-color: rgba(188, 164, 164, .5);
  border-radius: 12px;
  overflow: hidden;
  color: #fff;
}

.bull-form::before{
  content: "";
  display: block;
  width: 150px;
  height: 150px;
  background-image: url("./gifer.gif");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bull-form h2 {
  width: 250px;
  margin: 0 auto;
  order: -1;
  font-size: 25px;
  border-radius: 12px;
}

.bull-form p {
  font-size: 20px;
  padding: 0 16px;
}


.bull-form-counter{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  width: 100%;
  border: 1px solid #fff;
  font-size: 40px;
  gap: 15px;
  color: #fff;
  margin: 0 auto;
  margin-block-start: 30px;
}

.bull-form-counter span{
  width: 20px;
}

.bull-form-counter .claim-btn{
  position: absolute;
  right: 10px;
}

.bull-btn{
  align-self: center;
  font-size: 40px;
  line-height: 1;
  color: #fff
}

.bull-btn[data-pos='minus'] {
  position: relative;
  top: -3px;
}

.bull-claimers{
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  margin: 32px auto;
  width: 100%;
  max-width: 450px;
}

.claim-btn {
  max-width: 100px;
  width: 100%;
  height: 40px;
  font-family: 'Anton', cursive;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  background-color: #1a212b;
  color: #fff;
  transition: background-color .5s ease, color .5s ease;
}

.connect-wallet{
  width: 250px;
  height: 50px;
  border: 1px solid #000;
  font-family: 'Anton', cursive;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  background-color: #1a212b;
  color: #fff;
  border-radius: 12px;
  transition: background-color .5s ease, color .5s ease;
  flex-shrink: 0;
}

.conencted{
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.conencted > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.conencted::before{
  content: '';
  display: block;
  width: 40px;
  height: 30px;
  flex-shrink: 0;
  background-image: url('./wallet.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.bull-form > img {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;
}

a.claim-btn {
  display: block;
  max-width: 100%;
  margin: 8px auto 24px auto;
  background-color: #1a212b;
  color: #fff;
}

.bull-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  top: 20px;
  left: 0;
  min-width: 100px;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  z-index: 10;
  padding: 0 16px;
}

.greed-socials{
  display: flex;
  align-items: center;
  gap: 10px;
}

.extreme{ 
  position: absolute;
  top: 30%;
  left: 50px;
  background-color: #fff;
  padding: 5px 16px;
  font-size: 36px;
  border-radius: 12px;
  color: red;
  z-index: 2;
}

.bull-link-item{
  display: block;
  width: 35px;
  height: 35px;
}

.bull-link-item svg {
  width: 100%;
  height: 100%;
}

.counter{ 
  margin-block-start: 22px !important;
  font-size: 45px !important;
}

@media screen and (max-width: 992px) {
  .bull-container > img{
    object-fit: cover;
  }
  .bull-container{
    flex-wrap: wrap;
    justify-content: center;
  }
  .bull-wrapper{
    order: -1;
  }
}

@media screen and (max-width: 768px) {
  .bull-form > img {
    display: none;
  }
  .bull-container h1{
    font-size: 28px;
    position: relative;
    bottom: 0;
    order: -1;
    transform: none;
    left: 0;
    text-shadow: 2px 4px #000;
  }
}

@media screen and (max-width: 567px) {
  .bull-container{
    background-position: 85% 0px;
  }
}

.coming-soon{
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 22px;
}

.transaction-hash{
  display: flex;
  align-items: center;
}
